import axios from "axios";
import { persistStore } from "redux-persist";
import Cookies from "universal-cookie";
import store from "../redux/store";

const cookies = new Cookies();
const deviceIdKey = "device_id";
let deviceId = cookies.get(deviceIdKey);

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,

  headers: {
    "Content-Type": "application/json",
    "X-Header-DeviceID": deviceId,
  },
});
const token = cookies.get("token");
if (token && deviceId) {
  axiosInstance.defaults.headers.common["Authorization"] = token;
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      cookies.remove("token", {
        path: "/",
      });
      window.location.href = `${process.env.REACT_APP_HOST_URL}/auth/login`;
    } else if (error.response) {
      console.error("Error response:", error.response.data);
      console.error("Error status:", error.response.status);
      console.error("Error headers:", error.response.headers);
    } else if (error.request) {
      console.error("Error request:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    return Promise.reject(error);
  }
);

const clearPersistedStorage = () => {
  const persistor = persistStore(store);
  persistor.purge();

  console.log("Redux Persist Storage cleared!");
};

export const removeToken = () => {
  clearPersistedStorage();
  cookies.remove("token", { path: "/" });
  delete axiosInstance.defaults.headers.common["Authorization"];
};

export default axiosInstance;
