import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../libs/axiosIntance";
import { RoleModel } from "../../../model/role/RoleModel";

interface RoleState {
  roleInfo: RoleModel;
  loading: boolean;
  error: string | null;
}

const initialState: RoleState = {
  roleInfo: {
    role: "",
  },
  loading: false,
  error: null,
};

export const fetchRoleInfo = createAsyncThunk<RoleModel, void>(
  "RoleInfo/fetchRoleInfo",
  async () => {
    try {
      const response = await axiosInstance.get(`/user/check-token`);
      return response.data;
    } catch (error) {
      console.error("Error fetching list role:", error);
      throw error;
    }
  }
);

const RoleInfoSlice = createSlice({
  name: "RoleInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchRoleInfo.fulfilled,
        (state, action: PayloadAction<RoleModel>) => {
          state.loading = false;
          state.roleInfo = action.payload;
        }
      )
      .addCase(fetchRoleInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch list Role";
        console.error("Error fetching list Role:", action.error.message);
      });
  },
});

export const RoleInfoService = {
  action: RoleInfoSlice.actions,
  fetchRoleInfo,
};
const RoleInfoReducer = RoleInfoSlice.reducer;
export default RoleInfoReducer;
