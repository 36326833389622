import Cookies from "universal-cookie";
import { useAppDispatch } from "../redux/store";
import React, { lazy, useEffect, useState } from "react";
import { fetchRoleInfo } from "../redux/reducers/role/roleSlice";
import { useNavigate } from "react-router-dom";

const LoadingComponent = React.lazy(() =>
  import("super_apps_frontend/LoadingComponent").catch((error) => {
    console.error("Error loading remote component:", error);
    return { default: () => <div>Error Loading Component</div> };
  })
);

interface ProtectedProps {
  children: React.ReactNode;
}

export default function Protected({ children }: ProtectedProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get("token");

  const [isLoading, setIsLoading] = useState(true);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleInfo = await dispatch(fetchRoleInfo() as any).unwrap();
        console.log("Role Info:", roleInfo);

        // Validasi token dan role
        if (!token || !roleInfo.role || roleInfo.role !== "supplier") {
          switch (roleInfo.role) {
            case "distributor":
              window.location.href = `${process.env.REACT_APP_HOST_URL}/distributor/dashboard`;
              break;
            case "vendor":
              window.location.href = `${process.env.REACT_APP_HOST_URL}/vendor/pesanan`;
              break;
            case "konsumen":
              window.location.href = `${process.env.REACT_APP_HOST_URL}`;
              break;
            case "produsen":
              window.location.href = `${process.env.REACT_APP_HOST_URL}/produsen`;
              break;
            default:
              window.location.href = `${process.env.REACT_APP_HOST_URL}/auth/login`;
          }
        } else {
          setIsFetched(true);
        }
      } catch (error) {
        window.location.href = `${process.env.REACT_APP_HOST_URL}/auth/login`;
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, navigate, token]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isFetched) {
    return <>{children}</>;
  }

  return null;
}
