import React from "react";

export default function Error505() {
  return (
    <>
      <div className="h-screen flex items-center justify-center content-center">
        <img src="/images/error500.jpg" alt="" className="w-[800px]" />
      </div>
    </>
  );
}
