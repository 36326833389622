import React, { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Protected from "../middleware";

const DashboardSupplier = lazy(() => import("../pages/dashboard"));
const DefaultLayout = lazy(() => import("../layouts/dashboard/Default"));
const Error500 = lazy(() => import("../pages/errors/Error505"));
const Error404 = lazy(() => import("../pages/errors/Error404"));
const LoadingComponent = React.lazy(() =>
  import("super_apps_frontend/LoadingComponent").catch((error) => {
    console.error("Error loading remote component:", error);
    return { default: () => <div>Error Loading Component</div> };
  })
);

export const AppRoutes = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <Suspense fallback={<LoadingComponent />}>
          <Protected>
            <DefaultLayout />
          </Protected>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<LoadingComponent />}>
              <DashboardSupplier />
            </Suspense>
          ),
        },
        {
          path: "error/500",
          element: (
            <Suspense fallback={<LoadingComponent />}>
              <Error500 />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<LoadingComponent />}>
          <Error404 />
        </Suspense>
      ),
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

export default AppRoutes;
