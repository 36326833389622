import "./App.css";
import { RouterProvider } from "react-router-dom";
import AppRoutes from "./routes";
import ErrorBoundary from "./pages/errors/ErrorBoundary";

function App() {
  return (
    <>
      <div className="App">
        <ErrorBoundary>
          <RouterProvider
            router={AppRoutes}
            key={Math.random()}
            future={{
              v7_startTransition: true,
            }}
          />
        </ErrorBoundary>
      </div>
    </>
  );
}

export default App;
